import React from 'react';
import './index.scss';

const EducationItem = ({ education }) => {
  const { title, term, description, githubUrl, blogUrl } = education;
  return (
    <div className='education'>
      <div className='item__details'>
        <div className='term'>[{term}]</div>
        <div className='title'>{title}</div>
        <div className='description'>{description}</div>
      </div>
      <div className="project__links">
        {githubUrl && (
          <a href={githubUrl} target="_blank" rel="noreferrer">
            <div className="github-logo" />
        </a>
          
        )}
      </div>
      <div className="blog__links">
        {blogUrl && (
          <a href={blogUrl} target="_blank" rel="noreferrer">
          <div className="blog-logo" />
      </a>
        )}
      </div>
    </div>
  );
};

export default EducationItem;
