import './index.scss';

import EtcItem from './EtcItem';
import React from 'react';
import SubTitle from '../common/SubTitle'
import myData from '../../db.json';

const Etc = () => {
  const { etcs } = myData;

  return (
    <>
      {etcs &&
        <>
          <SubTitle>ETC</SubTitle>
          <div className='etc-container'>
            {etcs && etcs.map((etc, index) => (
              <EtcItem key={index} etc={etc} />
            ))}
          </div>
        </>
      }
    </>
  );
};

export default Etc;
