import "./index.scss";

import React, { useCallback, useRef, useState } from "react";
import noImage from "../../../assets/static/images/no-image.png";
import { ReactComponent as UpArrow } from "../../../assets/static/images/arrow-up.svg";
import { ReactComponent as DownArrow } from "../../../assets/static/images/arrow-down.svg";
const ExperienceItem = ({ experience }) => {
  const {
    imgUrl,
    githubUrl,
    blogUrl,
    title,
    term,
    description,
    tags,
    problem1,
    process1,
    WhatILearned1,
    project1,
    term1,
    problem2,
    process2,
    result1,
    result2,
    WhatILearned2,
    project2,
    term2,
    experienceUrl,
    isPog1,
    isPog2,
    isPog3,
    isPog4,
    isPog5,
    isPog6,
    isPog7,
    isPog8,
    isPog9,
    isSOPT,
    isPoket,
    project3,
    term3,
    problem3,
    process3,
    result3,
    WhatILearned3,
    project4,
    problem4,
    term4,
    process4,
    result4,
    WhatILearned4,
    project5,
    term5,
    problem5,
    process5,
    result5,
    WhatILearned5,
    project6,
    term6,
    problem6,
    process6,
    result6,
    WhatILearned6,
    project7,
    term7,
    problem7,
    process7,
    result7,
    WhatILearned7,
    project8,
    term8,
    problem8,
    process8,
    result8,
    WhatILearned8,
    project9,
    term9,
    problem9,
    process9,
    result9,
    WhatILearned9,
  } = experience;

  const addDefaultSrc = ({ target }) => (target.src = noImage);
  const wrapperRef = useRef(null);
  const contentsRef = useRef(null);
  const [isCollapse, setIsCollapse] = useState(false);

  const handleButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (wrapperRef.current === null || contentsRef.current === null) {
        return;
      }
      if (wrapperRef.current.clientHeight > 0) {
        wrapperRef.current.style.height = "0";
        wrapperRef.current.style.visibility = "hidden";
      } else {
        wrapperRef.current.style.visibility = "visible";

        wrapperRef.current.style.height = `${
          contentsRef.current.clientHeight + 30
        }px`;
      }
      setIsCollapse(!isCollapse);
    },
    [isCollapse]
  );
  return (
    // <a href={experienceUrl} target="_blank" rel="noopener noreferrer">
    <div className="experience">
      <div className="item__image">
        <img
          src={imgUrl}
          alt={`${title} logo 이미지`}
          onError={addDefaultSrc}
        />
      </div>
      <div className="item__details">
        <div className="details-header">
          <div className="details-header__contents">
            <div className="title">
              {title} <span className="term">({term})</span>
            </div>
            <div className="description">{description}</div>
            {tags && (
              <div className="tags">
                {tags.map((tag) => (
                  <div className="tag">{tag}</div>
                ))}
              </div>
            )}

            {githubUrl && (
              <a 
              className="github_link" 
              href={githubUrl}
              target="_blank"
              rel="noreferrer">
                <span className="github-logo" />
              </a>
            )}

            {blogUrl && (
              <a 
              className="blog_link" 
              href={blogUrl}
              target="_blank"
              rel="noreferrer">
                <span className="blog-logo" />
              </a>
            )}
          </div>
                  
          
    
          <div className="arrow" onClick={handleButtonClick}>
            {isCollapse ? <UpArrow /> : <DownArrow />}
          </div>
        </div>
        <div className="details-info__wrapper" ref={wrapperRef}>
          <div className="details-info" ref={contentsRef}>
            {project1 && (
              <div className="sub_project_title">
                프로젝트 : {project1} <span className="term">({term1})</span>
              </div>
            )}

            {problem1 && <div className="sub_title">문제</div>}
            {problem1 && <div className="sub_detail">{problem1}</div>}

            {process1 && <div className="sub_title">진행 과정</div>}
            {process1 && <div className="sub_detail">{process1}</div>} 
            {
            experienceUrl && (
              <div className= "oneLine">
              <a
                className="related_link"
                href={experienceUrl}
                target="_blank"
                rel="noreferrer"
              >
                {process1} 
              </a>
              </div>
            )}
            





            {isSOPT && <div className="sub_title">진행 과정</div>}
            {isSOPT && <div className="sub_detail">개발자로서 더 나은 협업을 하려면 어떻게 해야 할까? 고민하며 프로젝트 시작 전, <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/5?category=923736'
                target="_blank"
                rel="noreferrer"
              >
                '코드 컨벤션'
              </a>
            , <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/6?category=923736'
                target="_blank"
                rel="noreferrer"
              >
                '커밋 컨벤션'
              </a>
              , <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/7?category=923736'
                target="_blank"
                rel="noreferrer"
              >
                'git flow'
              </a>
              , <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/8?category=923736'
                target="_blank"
                rel="noreferrer"
              >
                'git 명령어 가이드'
              </a>
              , '이슈 PR 관리'에 대해 공부하고, 공부 내용을 블로그에 정리해서 팀원들에게 공유했습니다. 또한 이미지 업로드 시 AWS Lambda가 이미지 크기를 줄여서 S3에 이미지를 저장할 수 있도록 프로젝트에 적용했습니다. </div>}


            {isPog1 && <div className="sub_summary">AWS의 Elastic Beanstalk를 사용하여 배포하고 있습니다. Github Actions를 활용하여 CI/CD를 진행 중입니다. AWS RDS(PostgreSQL)를 RDBMS로 사용하고 있고, AWS ElastiCache(Redis)를 활용해서 캐시 데이터를 사용하고 있습니다.</div>}
            {isPog1 && <div className="sub_summary">서버를 앱 서버, 푸시 서버 두 개로 운영하여, 하나의 서버가 장애가 나더라도, 다른 서버에 장애가 전파되지 않도록 구성했습니다. 푸시 서버의 경우 Redis 기반 큐인 Bull 큐를 활용하여 동기 처리 방식으로 진행되던 푸시 알림을 비동기 처리하도록 구성했습니다.</div>} 
            {isPog1 && <div className="sub_summary">배포 담당과 비즈니스 로직 담당으로 나누어 프로젝트를 진행했습니다. 저는 비즈니스 로직을 담당했고, 아래 작성된 프로젝트 진행을 홀로 진행했습니다.</div>}
            


            {/* {isPog1 && <div className="sub_detail">1. API 응답시 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/583?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                인스턴스를 JSON 객체로 직렬화
              </a>
              함으로써 전달해야만 하는 정보만 전달하도록 설정했습니다. 또한 API 요청에서 받은 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/621?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                JSON 객체를 인스턴스화 하는 역직렬화
              </a>
              를 통해 리터럴 객체의 추가 가공에 필요한 로직을 한 곳에 모아두고 활용함으로써 응집력있는 코드를 작성했습니다.</div>}
              
            {isPog1 && <div className="sub_detail">2. 조회 쿼리 속도를 높이기 위해 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/698?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                쿼리 실행 계획
              </a>
            을 살펴보며 인덱스를 활용한 쿼리를 작성하고자 노력했습니다.</div>}
            {isPog1 && <div className="sub_detail">3. <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/665?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                TypeORM 마이그레이션
              </a>
              <span>, </span>
              <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/667?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                Seeding을 활용
              </a>

              하여 대규모 테스트 데이터를 추가한 후 쿼리 튜닝을 하기 위해 노력했습니다.</div>}
            {isPog1 && <div className="sub_detail">4.
            <span> </span> 
            <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/704?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                모노레포 방식으로 프로젝트를 설계
              </a>
              하여 별도 배포될 애플리케이션과 공유 라이브러리 분리했습니다.</div>}
              {isPog1 && <div className="sub_detail">5. TypeORM의 QueryRunner를 활용한 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/678?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                트랜잭션 제어
              </a>

            를 통해 데이터 저장 API를 관리했습니다.</div>}
            {isPog1 && <div className="sub_detail">6. 일정 시간 이상 쿼리를 수행하지 못하는 슬로우 쿼리 발생시 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/695?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                설정한 Timeout 시간
              </a>
            이 지나면 쿼리를 강제 종료하도록 설정함으로써 슬로우 쿼리로 인한 장애 전파를 막기 위해 노력했습니다. 또한 유휴 커넥션 수가 부족해서 생길 수 있는 문제를 대비하기 위해 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/696?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                커넥션 풀
              </a>을 조정 방법을 알아둠으로써 장애 대비를 위해 노력했습니다.
              </div>}
            {isPog1 && <div className="sub_detail">7. E2E, 통합, 단위 테스트 코드를 별도로 작성하여, 코드를 자유롭게 수정할 수 있도록 구성했고, 단위 테스트 코드 작성 시 mock을 활용하지 않고 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/650?category=1003044'
                target="_blank"
                rel="noreferrer"
              >
                stub 객체를 활용
              </a>
            함으로써 의존성 설계가 잘못된 곳은 없는지 스스로 크로스 체킹하면서 개발했습니다.</div>}
            
            {isPog1 && <div className="sub_title">결과</div>}
            
            
            {isPog1 && <div className="sub_detail">1. 인덱스를 활용하여 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/666?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                1000만 건의 데이터를 기준으로 657ms 걸리던 조회 쿼리를 0.023ms의 속도로 조회할 수 있도록 쿼리 성능 개선
              </a>
              을 했습니다.
            </div>}
            
            
              {isPog1 && <div className="sub_detail">2. Sentry와 Slack을 활용하여 에러 발생 시 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/716?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                에러 내용을 슬랙에 공유
              </a>
            되도록 설정했습니다.
              </div>} */}





            {result1 && <div className="sub_title">결과</div>}
            {result1 && <div className="sub_detail">{result1}</div>}


            {WhatILearned1 && <div className="sub_title">배운 점</div>}
            {WhatILearned1 && <div className="sub_detail">{WhatILearned1}</div>}








            {project2 && <div className="space">----</div>}

            {project2 && (
              <div className="sub_project_title">
                프로젝트 : {project2}
                <span className="term">({term2})</span>
              </div>
            )}

            {problem2 && <div className="sub_title">문제</div>}
            {problem2 && <div className="sub_detail">{problem2}</div>}

            {process2 && <div className="sub_title">진행 과정</div>}
            {process2 && <div className="sub_detail">{process2}</div>}

  







            {isPoket && <div className="sub_title">진행 과정</div>}
            {isPoket && <div className="sub_detail">재방문율을 올릴 수 있는 방안으로 알림 서비스를 기획하고 개발했습니다. 게시글에 댓글 또는 대댓글이 생성될 때 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/175?category=923736'
                target="_blank"
                rel="noreferrer"
              >
                푸시 알림이 가도록 설정했고, Node Schedule 모듈을 활용
              </a>
            하여 전체 고객에게 특정 시간에 이벤트 공지를 알리는 푸시 알림이 가도록 설정했습니다.</div>}
            





            {isPog2 && <div className="sub_title">진행 과정</div>}
            {isPog2 && <div className="sub_detail"><a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/630?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                FCM 공식 문서를 모두 분석
              </a>
              하면서, FCM Topic을 활용하면 사용자의 토큰을 찾지 않아도 푸시를 보낼 수 있다는 것을 알았습니다. 
              Topic을 활용하기 위해 사용자가 소환사를 즐겨찾기 할 경우, <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/724?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                클라이언트 로직에서 소환사의 id를 FCM topic으로 구독
              </a>
              하도록 설정했습니다.
              </div>}


            {/*               
            {isPog2 && <div className="sub_detail">3. 특정 시간마다 특정 소환사의 전적이 갱신됐는지 확인하기 위해 Redis의 set 자료구조를 활용하여 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/701#redis%EB%A5%BC-%EC%96%B4%EB%96%BB%EA%B2%8C-%ED%99%9C%EC%9A%A9%ED%96%88%EB%82%98?'
                target="_blank"
                rel="noreferrer"
              >
                소환사의 id를 summonerId 키에 저장했고, summonerId를 조회
              </a>
              하면 저장된 모든 summonerId를 불러올 수 있었습니다.</div>}
            {isPog2 && <div className="sub_detail">4. 불러온 소환사 id를 Riot의 전적 정보 조회 API의 파라미터로 지정하여 특정 소환사 전적을 조회했습니다. FCM에 푸시 메시지를 전달할 때 동기적으로 동작하기 때문에 이를 비동기적으로 처리하기 위해 큐를 활용했습니다. 만약 전적이 변경된 경우 summonerId를 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/673?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                Redis 기반의 Bull.js를 활용한 Queue에 전달
              </a>
            하여, <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/724'
                target="_blank"
                rel="noreferrer"
              >
                비동기적으로 FCM 서버에 푸시 알림
              </a>
            을 보내도록 개발했습니다.</div>} */}
            


            {result2 && <div className="sub_title">결과</div>}
            {result2 && <div className="sub_detail">{result2}</div>}

            {WhatILearned2 && <div className="sub_title">배운 점</div>}
            {WhatILearned2 && <div className="sub_detail">{WhatILearned2}</div>}








            {project3 && <div className="space">----</div>}
            {project3 && (
              <div className="sub_project_title">
                프로젝트 : {project3}
                <span className="term">({term3})</span>
              </div>
            )}

            {problem3 && <div className="sub_title">문제</div>}
            {problem3 && <div className="sub_detail">{problem3}</div>}

            {process3 && <div className="sub_title">진행 과정</div>}
            {process3 && <div className="sub_detail">{process3}</div>} 

            {isPog3 && <div className="sub_title">진행 과정</div>}
            {isPog3 && <div className="sub_detailList">1. 동기적으로 동작하는 로직을 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/724?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                비동기적으로 처리하기 위해 Queue를 활용
              </a>
              했습니다. FCM 서버에 보내야 할 푸시 정보를 Queue에 전달해서 처리함으로써 Node.js의 메인 스레드가 계속해서 멈춰있지 않도록 설계했습니다.
              </div>}
              {isPog3 && <div className="sub_detailList">2. 아이템에 대한 고객 반응을 살펴보기 위해서는 서비스를 빠르게 설계해서 개발할 수 있어야 했습니다. <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/673?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                Kafka와 같은 이벤트 브로커를 설계하기에는 많은 시간이 걸릴 수 있을 것으로 생각하여 메시지 브로커 중심으로 Queue를 설계
              </a>
              했습니다.
              </div>}
              {isPog3 && <div className="sub_detailList">3. RabbitMQ를 활용하려 했으나, 제대로 활용하려면 관리 비용이 든다는 것을 파악했습니다. AWS의 SQS의 경우도 서비스 초기에는 비용이 들지 않더라도 사용자가 조금만 많아지더라도 큰 비용이 들 수 있다고 판단했습니다.
              </div>}
              {isPog3 && <div className="sub_detailList">4. 서비스에서 Redis를 활용하고 있으니, Redis 기반의 Queue를 활용하면, 비용 문제도 걱정하지 않으면서도 빠르게 Queue를 구현하여 고객반응을 살펴볼 수 있다고 판단했습니다. 이를 통해 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/679?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                Redis 기반의 Queue인 bull.js를 활용하여 푸시 알림을 비동기 처리하도록 설정
              </a>
              했습니다.
              </div>}

            
            {result3 && <div className="sub_title">결과</div>}
            {result3 && <div className="sub_detail">{result3}</div>}

            {WhatILearned3 && <div className="sub_title">배운 점</div>}
            {WhatILearned3 && <div className="sub_detail">{WhatILearned3}</div>}



            {project4 && <div className="space">----</div>}
            {project4 && (
              <div className="sub_project_title">
                프로젝트 : {project4}
                <span className="term">({term4})</span>
              </div>
            )}

            {problem4 && <div className="sub_title">문제</div>}
            {problem4 && <div className="sub_detail">{problem4}</div>}

            {isPog4 && <div className="sub_title">문제</div>}
            {isPog4 && <div className="sub_detailList">1. POG 서비스는 1분 마다 사용자가 즐겨찾기 한 소환사 중, 전적이 변경된 소환사를 파악해서, 전적이 변경된 소환사를 즐겨찾기한 사용자에게 푸시알림을 보냅니다. 이때 전적이 변경된 소환사를 파악하기 위해서는 DB에 저장된 소환사를 전부 조회해야 했습니다. 전체 소환사 정보를 조회하는 과정에서 DB에 부하가 갈 수 있다고 생각했습니다.
              </div>}
            {isPog4 && <div className="sub_detailList">2. 소환사의 전적이 변경됐는지 파악하기 위해 특정 소환사의 정보를 받아오는 Riot API를 호출했습니다. Riot API를 통해 받은 소환사 정보와 데이터베이스에 저장된 소환사 정보를 비교했습니다. 만약 비교 값이 다르다면, 데이터베이스에 최신 전적을 저장했습니다. DB에 수정 쿼리를 요청하는 과정에서 부하가 갈 수 있다고 생각했습니다.
              </div>}


            {process4 && <div className="sub_title">진행 과정</div>}
            {process4 && <div className="sub_detail">{process4}</div>} 

            {isPog4 && <div className="sub_title">진행 과정</div>}
            {isPog4 && <div className="sub_detailList">1. 소환사를 즐겨찾기할 경우, <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/701?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                소환사의 Id와 전적을 Redis에 저장
              </a>
              했습니다.
              </div>}
              {isPog4 && <div className="sub_detailList">2. 현재 Redis에 저장된 소환사의 전적과 Riot이 제공하는 소환사 전적 정보를 비교해서 소환사의 전적이 갱신될 때마다 Redis의 데이터를 수정하도록 설계했습니다.
              </div>}
              {isPog4 && <div className="sub_detailList">3. 만약 Redis에 장애가 발생해서 데이터가 모두 휘발됐을 때를 대비하여, Redis에 소환사의 전적을 다시 저장할 수 있는 롤백 기능을 개발했습니다.
              </div>}

            {result4 && <div className="sub_title">결과</div>}
            {result4 && <div className="sub_detail">{result4}</div>}

            {WhatILearned4 && <div className="sub_title">배운 점</div>}
            {WhatILearned4 && <div className="sub_detail">{WhatILearned4}</div>}


            {project5 && <div className="space">----</div>}
            {project5 && (
              <div className="sub_project_title">
                프로젝트 : {project5}
                <span className="term">({term5})</span>
              </div>
            )}

            {problem5 && <div className="sub_title">문제</div>}
            {problem5 && <div className="sub_detail">{problem5}</div>}

            {process5 && <div className="sub_title">진행 과정</div>}
            {process5 && <div className="sub_detail">{process5}</div>} 

            {isPog5 && <div className="sub_title">진행 과정</div>}

              {isPog5 && <div className="sub_detailList">1. E2E, 통합, 단위 테스트 코드를 작성했습니다. 단위 테스트 코드 작성 시 mock을 활용하지 않고 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/category/TDD/Nest.js'
                target="_blank"
                rel="noreferrer"
              >
                stub 객체를 활용함으로써 의존성 설계가 잘못된 곳은 없는지 스스로 크로스 체킹
              </a>
              하면서 개발했습니다. 
              </div>}
              {isPog5 && <div className="sub_detailList">2. Github Action을 활용하여 PR시 E2E, 통합, 단위 테스트 코드를 작동시켜 코드에 문제가 생긴다면 문제를 빠르게 해결할 수 있도록 설계했습니다.
              </div>}

            
            {result5 && <div className="sub_title">결과</div>}
            {result5 && <div className="sub_detail">{result5}</div>}

            {WhatILearned5 && <div className="sub_title">배운 점</div>}
            {WhatILearned5 && <div className="sub_detail">{WhatILearned5}</div>}




            {project6 && <div className="space">----</div>}
            {project6 && (
              <div className="sub_project_title">
                프로젝트 : {project6}
                <span className="term">({term6})</span>
              </div>
            )}

            {problem6 && <div className="sub_title">문제</div>}
            {problem6 && <div className="sub_detail">{problem6}</div>}

            {process6 && <div className="sub_title">진행 과정</div>}
            {process6 && <div className="sub_detail">{process6}</div>} 

            {isPog6 && <div className="sub_title">진행 과정</div>}

              {isPog6 && <div className="sub_detailList">1. 어떤 데이터베이스를 활용하는 것이 서비스를 발전시키는 데 도움이 될 수 있을지 알기 위해 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/664?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                MySQL 과 PostgreSQL를 비교
              </a>
              했습니다. 단순 CRUD 쿼리는 MySQL을 활용하는 것이 성능이 좋았지만, 복잡한 쿼리를 진행할 때는 PostgreSQL의 성능이 좋은 것을 파악했습니다. 서비스의 특성상 푸시 서비스를 개발할 때 대규모의 조회 쿼리를 빠르게 처리해야 했기에, PostgreSQL 을 활용하는 것이 더 효과적일 것으로 판단했습니다.
              </div>}
              {isPog6 && <div className="sub_detailList">2. RDS PostgreSQL 9.6 버전을 활용하면서, 커버링 인덱스가 적용이 안된다는 것을 파악하여 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/672?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
              PostgreSQL의 버전 별로 어떤 기능을 지원하는 지 분석
              </a>
              했습니다. 그 결과 PostgreSQL의 11버전 이상부터 안전하게 인덱스 적용을 할 수 있다고 판단하여 AWS RDS의 PostgreSQL 버전을 올려서 활용했습니다.
              </div>}

              {isPog6 && <div className="sub_detailList">3. PostgreSQL 을 활용하여 직접 테이블을 관리하는 것이 아닌, <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/665?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                TypeORM의 마이그레이션을 활용하여 테이블을 관리
              </a>
              했습니다. 이를 통해 DB 관리의 어려움을 줄일 수 있었습니다.
              </div>}
              {isPog6 && <div className="sub_detailList">4. 쿼리 최적화를 위해 대규모의 테스트 데이터를 쌓으려 노력했습니다. 그 과정에서  <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/667?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                TypeORM의 Seeding을 활용하여 대규모의 테스트 데이터를 축적
              </a>
              했습니다.
              </div>}
              {isPog6 && <div className="sub_detailList">5. 조회 쿼리 속도를 높이기 위해 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/666?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                쿼리 실행 계획을 살펴보며 인덱스를 활용한 쿼리를 작성
              </a>
              하고자 노력했습니다.
              </div>}

              {isPog6 && <div className="sub_detailList">6. TypeORM의 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/678?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                QueryRunner를 활용한 트랜잭션 제어
              </a>
              를 통해 데이터 저장 API를 관리했습니다.
              </div>}

              {isPog6 && <div className="sub_detailList">7. 일정 시간 이상 쿼리를 수행하지 못하는 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/695?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                슬로우 쿼리 발생시 설정한 Timeout 시간이 지나면 쿼리를 강제 종료하도록 설정
              </a>
              함으로써 슬로우 쿼리로 인한 장애 전파를 막기 위해 노력했습니다.
              </div>}

              {isPog6 && <div className="sub_detailList">8. 유휴 커넥션 수가 부족해서 생길 수 있는 문제를 대비하기 위해 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/696?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                커넥션 풀을 조정하는 방법
              </a>
              을 알아둠으로써 장애 대비를 위해 노력했습니다.
              </div>}
            
            {result6 && <div className="sub_title">결과</div>}
            {result6 && <div className="sub_detail">{result6}</div>}

            {WhatILearned6 && <div className="sub_title">배운 점</div>}
            {WhatILearned6 && <div className="sub_detail">{WhatILearned6}</div>}





            {project7 && <div className="space">----</div>}
            {project7 && (
              <div className="sub_project_title">
                프로젝트 : {project7}
                <span className="term">({term7})</span>
              </div>
            )}

            {problem7 && <div className="sub_title">문제</div>}
            {problem7 && <div className="sub_detail">{problem7}</div>}

            {process7 && <div className="sub_title">진행 과정</div>}
            {process7 && <div className="sub_detail">{process7}</div>} 

            {isPog7 && <div className="sub_title">진행 과정</div>}

              {isPog7 && <div className="sub_detailList">1. 코드를 줄이기 위해, 에러가 발생할 때는 에러에 대한 응답을 모두 작성하는 것이 아닌 <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/707?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                ExceptionFilter를 활용하여 에러를 핸들링
              </a>
              했습니다.
              </div>}
              {isPog7 && <div className="sub_detailList">2. <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/716?category=1006727'
                target="_blank"
                rel="noreferrer"
              >
                Sentry와 Slack을 활용하여 에러 발생 시 에러 내용을 슬랙에 공유
              </a>
              되도록 설정했습니다.
              </div>}
              {isPog7 && <div className="sub_detailList">3. <a
                className="related_link"
                href='https://overcome-the-limits.tistory.com/585?category=973912'
                target="_blank"
                rel="noreferrer"
              >
                Winston을 활용하여 로깅을 활용
              </a>
              했습니다. 로깅을 위해 인터셉터를 구현했습니다.
              </div>}


            
            {result7 && <div className="sub_title">결과</div>}
            {result7 && <div className="sub_detail">{result7}</div>}

            {WhatILearned7 && <div className="sub_title">배운 점</div>}
            {WhatILearned7 && <div className="sub_detail">{WhatILearned7}</div>}





            {project8 && <div className="space">----</div>}
            {project8 && (
              <div className="sub_project_title">
                프로젝트 : {project8}
                <span className="term">({term8})</span>
              </div>
            )}

            {problem8 && <div className="sub_title">문제</div>}
            {problem8 && <div className="sub_detail">{problem8}</div>}

            {process8 && <div className="sub_title">진행 과정</div>}
            {process8 && <div className="sub_detail">{process8}</div>} 

            {isPog8 && <div className="sub_title">진행 과정</div>}

            {isPog8 && <div className="sub_detailList">Swagger를 활용하여 API 명세서를 작성했습니다. <a
              className="related_link"
              href='https://overcome-the-limits.tistory.com/742?category=1006727'
              target="_blank"
              rel="noreferrer"
            >
              express-basic-auth 라이브러리를 활용하여 Swagger 문서에도 보안
            </a>
            을 신경썼습니다.
            </div>}

            
            {result8 && <div className="sub_title">결과</div>}
            {result8 && <div className="sub_detail">{result8}</div>}

            {WhatILearned8 && <div className="sub_title">배운 점</div>}
            {WhatILearned8 && <div className="sub_detail">{WhatILearned8}</div>}

            {project9 && <div className="space">----</div>}
            {project9 && (
              <div className="sub_project_title">
                프로젝트 : {project9}
                <span className="term">({term9})</span>
              </div>
            )}

            {problem9 && <div className="sub_title">문제</div>}
            {problem9 && <div className="sub_detail">{problem9}</div>}

            {process9 && <div className="sub_title">진행 과정</div>}
            {process9 && <div className="sub_detail">{process9}</div>} 
            {isPog9 && <div className="sub_title">진행 과정</div>}

            {isPog9 && <div className="sub_detailList">1. API 응답시 <a
              className="related_link"
              href='https://overcome-the-limits.tistory.com/583?category=1006727'
              target="_blank"
              rel="noreferrer"
            >
              인스턴스를 JSON 객체로 직렬화
            </a>
            함으로써 전달해야만 하는 정보만 전달하도록 설정했습니다.
            </div>}
              
            {isPog9 && <div className="sub_detailList">2. API <a
              className="related_link"
              href='https://overcome-the-limits.tistory.com/621?category=1006727'
              target="_blank"
              rel="noreferrer"
            >
              요청에서 받은 JSON 객체를 인스턴스화 하는 역직렬화
            </a>
            를 통해 리터럴 객체의 추가 가공에 필요한 로직을 한곳에 모아두고 활용함으로써 응집력 있는 코드를 작성했습니다. 
            </div>}
            
            {isPog9 && <div className="sub_detailList">3. <a
              className="related_link"
              href='https://overcome-the-limits.tistory.com/704?category=1006727'
              target="_blank"
              rel="noreferrer"
            >
              모노레포 방식으로 프로젝트를 설계
            </a>
            하여 별도 배포될 비즈니스 로직과 라이브러리를 분리했습니다.
            </div>}

            {isPog9 && <div className="sub_detailList">4. 상대경로가 아닌, <a
              className="related_link"
              href='https://overcome-the-limits.tistory.com/598'
              target="_blank"
              rel="noreferrer"
            >
              절대 경로를 활용하여 모듈을 불러오도록 설계
            </a>
            했습니다.
            </div>}


            {result9 && <div className="sub_title">결과</div>}
            {result9 && <div className="sub_detail">{result9}</div>}

            {WhatILearned9 && <div className="sub_title">배운 점</div>}
            {WhatILearned9 && <div className="sub_detail">{WhatILearned9}</div>}


          </div>
        </div>
      </div>
    </div>
    // </a>
  );
};

export default ExperienceItem;
