import "./index.scss";

import React from "react";
import SubTitle from "../common/SubTitle";
import SubDescriptiion from "../common/SubDescription";
import myData from "../../db.json";
import ExperienceItem from "./ExperienceItem";

const Experience = () => {
  const { experiences } = myData;

  return (
    <>
      {experiences && (
        <>
          <SubTitle>Dev Experiences</SubTitle>
          <SubDescriptiion>
            우측 화살표를 클릭하면 자세한 정보를 살펴볼 수 있습니다.
          </SubDescriptiion>
          <div className="experience-container">
            {experiences.map((experience, index) => (
              <ExperienceItem key={index} experience={experience} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default Experience;
