import React from 'react';
import './index.scss';

const EtcItem = ({ etc }) => {
  const { title, term, description } = etc;
  return (
    <div className='etc'>
      <div className='item__details'>
        <div className='term'>[{term}]</div>
        <div className='title'>{title}</div>
        <div className='description'>{description}</div>
      </div>
    </div>
  );
};

export default EtcItem;
