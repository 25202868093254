import "./index.scss";

import React, { useCallback, useRef, useState } from "react";
import noImage from "../../../assets/static/images/no-image.png";
import { ReactComponent as UpArrow } from "../../../assets/static/images/arrow-up.svg";
import { ReactComponent as DownArrow } from "../../../assets/static/images/arrow-down.svg";
const ExperienceItem = ({ experience }) => {
  const {
    imgUrl,
    experienceUrl,
    title,
    term,
    description,
    tags,
    problem1,
    process1,
    result1,
    WhatILearned1,
    project1,
    term1,
    problem2,
    process2,
    result2,
    WhatILearned2,
    project2,
    term2,
  } = experience;

  const addDefaultSrc = ({ target }) => (target.src = noImage);
  const wrapperRef = useRef(null);
  const contentsRef = useRef(null);
  const [isCollapse, setIsCollapse] = useState(false);

  const handleButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (wrapperRef.current === null || contentsRef.current === null) {
        return;
      }
      if (wrapperRef.current.clientHeight > 0) {
        wrapperRef.current.style.height = "0";
        wrapperRef.current.style.visibility = "hidden";
      } else {
        wrapperRef.current.style.visibility = "visible";

        wrapperRef.current.style.height = `${
          contentsRef.current.clientHeight + 30
        }px`;
      }
      setIsCollapse(!isCollapse);
    },
    [isCollapse]
  );
  return (
    // <a href={experienceUrl} target="_blank" rel="noopener noreferrer">
    <div className="experience">
      <div className="item__image">
        <img
          src={imgUrl}
          alt={`${title} logo 이미지`}
          onError={addDefaultSrc}
        />
      </div>
      <div className="item__details">
        <div className="details-header">
          <div className="details-header__contents">
            <div className="title">
              {title} <span className="term">({term})</span>
            </div>
            <div className="description">{description}</div>
            {tags && (
              <div className="tags">
                {tags.map((tag) => (
                  <div className="tag">{tag}</div>
                ))}
              </div>
            )}
          </div>

          <div className="arrow" onClick={handleButtonClick}>
            {isCollapse ? <UpArrow /> : <DownArrow />}
          </div>
        </div>
        <div className="details-info__wrapper" ref={wrapperRef}>
          <div className="details-info" ref={contentsRef}>
            {project1 && (
              <div className="sub_project_title">
                프로젝트 : {project1} <span className="term">({term1})</span>
              </div>
            )}

            {problem1 && <div className="sub_title">문제</div>}
            {problem1 && <div className="sub_detail">{problem1}</div>}

            {process1 && <div className="sub_title">진행 과정</div>}
            {process1 && <div className="sub_detail">{process1}</div>}

            {result1 && <div className="sub_title">결과</div>}
            {result1 && <div className="sub_detail">{result1}</div>}

            {WhatILearned1 && <div className="sub_title">배운 점</div>}
            {WhatILearned1 && <div className="sub_detail">{WhatILearned1}</div>}

            {project2 && <div className="space">----</div>}

            {project2 && (
              <div className="sub_project_title">
                프로젝트 : {project2}
                <span className="term">({term2})</span>
              </div>
            )}

            {problem2 && <div className="sub_title">문제</div>}
            {problem2 && <div className="sub_detail">{problem2}</div>}

            {process2 && <div className="sub_title">진행 과정</div>}
            {process2 && <div className="sub_detail">{process2}</div>}

            {result2 && <div className="sub_title">결과</div>}
            {result2 && <div className="sub_detail">{result2}</div>}

            {WhatILearned2 && <div className="sub_title">배운 점</div>}
            {WhatILearned2 && <div className="sub_detail">{WhatILearned2}</div>}

            {experienceUrl && (
              <a
                className="related_link"
                href={experienceUrl}
                target="_blank"
                rel="noreferrer"
              >
                Related Link
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
    // </a>
  );
};

export default ExperienceItem;
