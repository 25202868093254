import './index.scss';

import React from 'react';

const Footer = () => {
  return (
    <div className='footer-container'>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.constpark.com'
      >
        <p className='footer-contents'>
          <span className='icon'>&copy; 2022</span> const portfolio
        </p>
      </a>
    </div>
  );
};

export default Footer;
