import React from 'react';
import './index.scss';

const SubDescription = ({ children }) => {
  return (
    <div className='subtitle'>
      <h5 className='subtitle__h5'>{children}</h5>
    </div>
  );
};

export default SubDescription;
